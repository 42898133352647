import React from 'react';
import { styled, StyledProps } from '@glitz/react';

function AccountIcon({ compose }: StyledProps) {
  return (
    <styled.Svg
      css={compose()}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 9 11"
    >
      <path
        fill="#484848"
        fillRule="evenodd"
        d="M4.5 0c1.657 0 3 1.343 3 3 0 1.185-.687 2.209-1.684 2.697C7.66 6.259 9 7.973 9 10c0 .276-.224.5-.5.5S8 10.276 8 10c0-1.933-1.567-3.5-3.5-3.5S1 8.067 1 10c0 .276-.224.5-.5.5S0 10.276 0 10c0-2.027 1.34-3.741 3.183-4.304C2.187 5.209 1.5 4.185 1.5 3c0-1.657 1.343-3 3-3zm0 1c-1.105 0-2 .895-2 2s.895 2 2 2 2-.895 2-2-.895-2-2-2z"
      />
    </styled.Svg>
  );
}

export default styled(AccountIcon);
