import React from 'react';
import { styled, StyledProps } from '@glitz/react';

function GlobeIcon({ compose }: StyledProps) {
  return (
    <styled.Svg
      css={compose()}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12 12"
    >
      <path
        fill="#484848"
        fillRule="evenodd"
        d="M6 0c3.314 0 6 2.686 6 6s-2.686 6-6 6-6-2.686-6-6 2.686-6 6-6zm1.989 6.501H4.01C4.13 9.084 5.14 11 6 11c.861 0 1.87-1.916 1.989-4.499zm-4.979 0H1.025c.185 1.866 1.397 3.43 3.062 4.12-.604-1-1.008-2.465-1.077-4.12zm7.965 0H8.99c-.069 1.655-.473 3.12-1.077 4.12 1.665-.69 2.877-2.254 3.062-4.12zM4.087 1.378l-.127.056C2.36 2.15 1.205 3.682 1.025 5.5H3.01c.068-1.655.473-3.12 1.077-4.122zM6 1c-.861 0-1.871 1.917-1.989 4.5H7.99c-.114-2.498-1.061-4.372-1.903-4.494L6 1zm1.913.379l.02.032c.593 1 .99 2.452 1.057 4.09h1.985c-.185-1.867-1.397-3.432-3.062-4.122z"
      />
    </styled.Svg>
  );
}

export default styled(GlobeIcon);
