import React, { useEffect, useState } from 'react';
import { theme } from '../../Theme';
import { media, pseudo } from '@glitz/core';
import { StyleOrStyleArray } from '@glitz/type';
import { styled, StyledProps } from '@glitz/react';
import { useKexInputValidation, ValidationParams } from './KexInputValidation';

type KexInputValidationType = {
  validation?: ValidationParams;
};

type KexInputType = StyledProps &
  KexInputValidationType & {
    onChange?: (value: string) => void;
    onBlur?: (value: string) => void;
    onKeyDown?: (event: React.KeyboardEvent) => void;
    title: string;
    type?: string;
    name?: string;
    isEmpty?: boolean;
    showLabel?: boolean;
  };

const KexInput = ({
  title,
  type = 'text',
  onChange,
  onBlur,
  onKeyDown,
  compose,
  name,
  validation,
  isEmpty,
  showLabel = true,
}: KexInputType) => {
  const validationDispatch = useKexInputValidation();

  const [hasError, setHasError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [hasTouched, setHasTouched] = useState<boolean>(false);
  const [hasValue, setHasValue] = useState<boolean>(false);

  const doValidation = (currentValue: string) => {
    if (!!validation) {
      const errorMessage = validation.errorMessage || '';
      let validationError = false;

      if (validation.active !== undefined && !validation.active) {
        return;
      }

      if (validation.pattern) {
        if (!validation.pattern.test(currentValue)) {
          validationError = true;
        }
      } else if (validation.required) {
        if (!currentValue) {
          validationError = true;
        }
      } else if (validation.minLength) {
        if (
          !currentValue ||
          (currentValue && currentValue.length < validation.minLength)
        ) {
          validationError = true;
        }
      } else if (validation.maxLength) {
        if (currentValue && currentValue.length > validation.maxLength) {
          validationError = true;
        }
      }

      if (validationError) {
        setHasError(true);
        setErrorMessage(errorMessage);
      }

      setHasValue(!!currentValue);
    }
  };

  const onInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const currentValue: string = e.currentTarget.value;

    doValidation(currentValue);
    onBlur && onBlur(e.currentTarget.value);
  };

  const onInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!!validation && validation.onTouched) {
      validation.onTouched(name); // TODO: Refactor? While it's okay to use callbacks since they was passed down, I don't like the fact
    }

    setHasError(false);
    setHasTouched(true);
  };

  useEffect(() => {
    validationDispatch({
      type: 'register',
      name: name,
      payload: {
        hasTouched: hasTouched,
        hasError: hasError,
        hasValue: hasValue,
      },
    });

    if (!!validation) {
      if (validation.backendValidation) {
        setHasError(true);
        setErrorMessage(validation.backendValidation.message);
      }
    }

    return () => {
      validationDispatch({ type: 'unregister', name: name, payload: {} });
    };
  }, [validationDispatch, name, hasTouched, hasError, hasValue, validation]);

  return (
    <InputGroup css={compose()}>
      {!isEmpty && showLabel && (
        <FormLabel css={hasError ? FormLabelError : {}}>
          {hasError ? errorMessage : title}
        </FormLabel>
      )}
      <FormInput
        data-notempty={!isEmpty && showLabel}
        placeholder={title}
        css={hasError ? FormInputError : {}}
        type={type}
        onChange={e => {
          onChange && onChange(e.currentTarget.value);
        }}
        onBlur={e => {
          onInputBlur(e);
        }}
        onKeyDown={e => {
          onKeyDown && onKeyDown(e);
        }}
        onFocus={e => {
          onInputFocus(e);
        }}
        name={name}
      />
    </InputGroup>
  );
};

const InputGroup = styled.div({
  position: 'relative',
});

const FormLabel = styled.label({
  font: { size: theme.large },
  letterSpacing: '0.02em',
  position: 'absolute',
  top: '12px',
  left: '26px',
  color: theme.middleGray,
});

const FormInput = styled.input({
  font: { size: theme.beta },
  width: '100%',
  height: theme.massive,
  padding: { x: '25px' },
  borderRadius: '8px',
  border: {
    xy: {
      style: 'solid',
      width: theme.tiny,
      color: theme.veryLightGray,
    },
  },
  ...pseudo([':nth-child(n)[data-notempty="true"]'], {
    paddingTop: theme.spacing(3),
  }),
  ...media(theme.mediaQuery.mediaMinLarge, {
    width: '100%',
  }),
});

const FormInputError: StyleOrStyleArray = {
  border: {
    xy: {
      color: theme.errorPrimary,
    },
  },
};

const FormLabelError: StyleOrStyleArray = {
  color: theme.errorText,
};

export default styled(KexInput);
