import React from 'react';
import { StyledProps } from '@glitz/react';
import { styled, theme } from '../../Theme';
import { Style } from '@glitz/type';

type PropType = StyledProps & {
  isOpen?: boolean;
};

function MenuIcon({ compose, isOpen }: PropType) {
  return (
    <styled.Svg
      css={compose()}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 12"
    >
      <g fill="#484848" fillRule="evenodd">
        <Path
          css={
            isOpen
              ? { ...TopPathOut, opacity: 1 }
              : { ...TopPathIn, opacity: 0 }
          }
          d="M13.5,1.5 C13.7761424,1.5 14,1.72385763 14,2 C14,2.27614237 13.7761424,2.5 13.5,2.5 L2.5,2.5 C2.22385763,2.5 2,2.27614237 2,2 C2,1.72385763 2.22385763,1.5 2.5,1.5 L13.5,1.5 Z"
          transform="translate(0 -1)"
        />
        <Path
          css={
            isOpen
              ? { ...CenterPathOut, opacity: 1 }
              : { ...CenterPathIn, opacity: 0 }
          }
          d="M15.5,6.5 C15.7761424,6.5 16,6.72385763 16,7 C16,7.27614237 15.7761424,7.5 15.5,7.5 L0.5,7.5 C0.223857625,7.5 0,7.27614237 0,7 C0,6.72385763 0.223857625,6.5 0.5,6.5 L15.5,6.5 Z"
          transform="translate(0 -1)"
        />
        <Path
          css={
            isOpen
              ? { ...BottomPathOut, opacity: 1 }
              : { ...BottomPathIn, opacity: 0 }
          }
          d="M13.5,11.5 C13.7761424,11.5 14,11.7238576 14,12 C14,12.2761424 13.7761424,12.5 13.5,12.5 L2.5,12.5 C2.22385763,12.5 2,12.2761424 2,12 C2,11.7238576 2.22385763,11.5 2.5,11.5 L13.5,11.5 Z"
          transform="translate(0 -1)"
        />
        <CrossG
          css={
            isOpen
              ? { ...animationCrossIn, transform: 'scale(0)' }
              : { ...animationCrossOut, transform: 'scale(1)' }
          }
        >
          <path
            d="M15.5,6.5 C15.7761424,6.5 16,6.72385763 16,7 C16,7.27614237 15.7761424,7.5 15.5,7.5 L0.5,7.5 C0.223857625,7.5 0,7.27614237 0,7 C0,6.72385763 0.223857625,6.5 0.5,6.5 L15.5,6.5 Z"
            transform="rotate(-45 6.793 6.5)"
          />
          <path
            d="M15.5,6.5 C15.7761424,6.5 16,6.72385763 16,7 C16,7.27614237 15.7761424,7.5 15.5,7.5 L0.5,7.5 C0.223857625,7.5 0,7.27614237 0,7 C0,6.72385763 0.223857625,6.5 0.5,6.5 L15.5,6.5 Z"
            transform="rotate(45 9.207 6.5)"
          />
        </CrossG>
      </g>
    </styled.Svg>
  );
}

export default styled(MenuIcon);

const animationDuration = theme.timings.threeTenths;

const animationMenuPathsIn = {
  animationName: {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
  animationFillMode: 'forwards',
  animationDuration,
} as Style;

const animationMenuPathsOut = {
  animationName: {
    from: {
      opacity: 1,
    },
    to: {
      opacity: 0,
    },
  },
  animationFillMode: 'forwards',
  animationDuration,
} as Style;

const TopPathIn = {
  ...animationMenuPathsIn,
  animationDelay: theme.timings.twoFifths,
};
const CenterPathIn = {
  ...animationMenuPathsIn,
  animationDelay: theme.timings.oneHalf,
};
const BottomPathIn = {
  ...animationMenuPathsIn,
  animationDelay: theme.timings.threeFifths,
};

const TopPathOut = {
  ...animationMenuPathsOut,
  animationDelay: theme.timings.oneFifth,
};
const CenterPathOut = {
  ...animationMenuPathsOut,
  animationDelay: theme.timings.oneTenth,
};
const BottomPathOut = {
  ...animationMenuPathsOut,
};

const Path = styled.path({});

const animationCrossIn = {
  animationName: {
    '0%': {
      transform: 'scale(0)',
    },
    '90%': {
      transform: 'scale(1.1)',
    },
    '100%': {
      transform: 'scale(1)',
    },
  },
  animationDuration,
  animationFillMode: 'forwards',
  animationDelay: theme.timings.threeTenths,
} as Style;

const animationCrossOut = {
  animationName: {
    '0%': {
      transform: 'scale(1)',
    },
    '10%': {
      transform: 'scale(1.1)',
    },
    '100%': {
      transform: 'scale(0)',
    },
  },
  animationFillMode: 'forwards',
  animationDuration,
} as Style;

const CrossG = styled.g({
  transformOrigin: 'center',
});
