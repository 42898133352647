import React from 'react';
import { styled, StyledProps } from '@glitz/react';

type SearchIconType = StyledProps & {
  onClick?: () => void;
};

function SearchIcon({ compose, onClick }: SearchIconType) {
  return (
    <styled.Svg
      css={compose()}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      onClick={() => onClick && onClick()}
    >
      <path
        fill="#484848"
        fillRule="evenodd"
        d="M6.997 12.997c-1.603 0-3.11-.624-4.243-1.758-2.34-2.34-2.34-6.145 0-8.485 2.338-2.34 6.145-2.34 8.485 0 1.134 1.134 1.758 2.64 1.758 4.243 0 1.603-.624 3.109-1.758 4.242-1.133 1.134-2.64 1.758-4.242 1.758m8.854 2.146l-3.57-3.568c1.107-1.271 1.716-2.877 1.716-4.578 0-1.87-.73-3.628-2.051-4.95-2.728-2.728-7.17-2.73-9.9 0-2.728 2.729-2.728 7.17 0 9.899 1.323 1.322 3.08 2.05 4.95 2.05 1.702 0 3.308-.608 4.579-1.714l3.568 3.569c.098.097.226.146.354.146.128 0 .256-.05.354-.146.195-.196.195-.512 0-.708"
      />
    </styled.Svg>
  );
}

export default styled(SearchIcon);
