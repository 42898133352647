import React from 'react';
import { styled, StyledProps } from '@glitz/react';

function ProfileIconFilled({ compose }: StyledProps) {
  return (
    <styled.Svg
      css={compose()}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 16"
    >
      <path
        fill="#F7EDDA"
        d="M13.5 15.5C13.5 11.91 10.59 9 7 9S.5 11.91.5 15.5h13z"
      />
      <path
        fill="#484848"
        fillRule="nonzero"
        d="M7 0c2.485 0 4.5 2.015 4.5 4.5 0 1.666-.905 3.12-2.25 3.898C12.016 9.4 14 12.206 14 15.5c0 .276-.224.5-.5.5s-.5-.224-.5-.5C13 11.902 10.305 9 7 9s-6 2.902-6 6.5c0 .276-.224.5-.5.5s-.5-.224-.5-.5c0-3.294 1.984-6.1 4.75-7.105C3.405 7.62 2.5 6.165 2.5 4.5 2.5 2.015 4.515 0 7 0zm0 1C5.067 1 3.5 2.567 3.5 4.5S5.067 8 7 8s3.5-1.567 3.5-3.5S8.933 1 7 1z"
      />
    </styled.Svg>
  );
}

export default styled(ProfileIconFilled);
