import React from 'react';
import { styled, StyledProps } from '@glitz/react';

function CartIcon({ compose }: StyledProps) {
  return (
    <styled.Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 21"
      css={compose()}
    >
      <styled.G css={compose()} fill="none" fillRule="evenodd">
        <styled.Path
          d="M8 0a4.513 4.513 0 014.495 4.289l.005.211V5H15a1 1 0 011 1v14a1 1 0 01-1 1H1a1 1 0 01-1-1V6a1 1 0 011-1h2.5v-.5C3.5 2.024 5.524 0 8 0zm7 6h-2.5v3.5a.5.5 0 01-.992.09L11.5 9.5V6h-7v3.5a.5.5 0 01-.992.09L3.5 9.5V6H1v14h14V6zM8 1a3.513 3.513 0 00-3.495 3.309L4.5 4.5V5h7v-.5C11.5 2.576 9.924 1 8 1z"
          fill="#FFF"
          fillRule="nonzero"
          css={compose()}
        />
      </styled.G>
    </styled.Svg>
  );
}

export default styled(CartIcon);
