import React from 'react';
import { styled, StyledProps } from '@glitz/react';

function ArrowRightIcon({ compose }: StyledProps) {
  return (
    <styled.Svg
      css={compose()}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#C4C4C4" />
      </mask>
      <g mask="url(#mask0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.7855 19.3488C8.69806 19.4326 8.55948 19.4303 8.47478 19.3438L7.65705 18.5081C7.57073 18.4199 7.57343 18.2781 7.66303 18.1932L14.0655 12.1295L7.65667 5.80688C7.57007 5.72144 7.56884 5.58207 7.65393 5.49512L8.47217 4.65895C8.5579 4.57134 8.69858 4.57028 8.78562 4.6566L15.999 11.8103C16.1754 11.9852 16.1729 12.2712 15.9935 12.443L8.7855 19.3488Z"
        />
      </g>
    </styled.Svg>
  );
}

export default styled(ArrowRightIcon);
