import React from 'react';
import KexLink from '../../Shared/KexLink/KexLink';
import { styled, theme } from '../../../Features/Theme';
import Link from '../../Shared/Models/Link.interface';
import useMedia from '../Hooks/useMedia';

type PropTypes = {
  breadcrumb: Link[];
};

function Breadcrumb({ breadcrumb }: PropTypes) {
  const isDesktop = useMedia(theme.mediaQuery.mediaMinLarge);
  return isDesktop ? (
    <BreadcrumbContainer>
      {breadcrumb.map((link, index) =>
        breadcrumb.length === index + 1 ? (
          <div key={index}>
            <SelectedSpan>{link.text}</SelectedSpan>
          </div>
        ) : (
          <BreadcrumbLink key={index} href={link.href}>
            <LinkText>{link.text}</LinkText>
            <Seperator> / </Seperator>
          </BreadcrumbLink>
        )
      )}
    </BreadcrumbContainer>
  ) : (
    <></>
  );
}

const BreadcrumbContainer = styled.div({
  position: 'absolute',
  top: theme.desktopHeaderHeight,
  fontSize: theme.beta,
  left: 0,
  right: 0,
  margin: { x: 'auto' },
  maxWidth: theme.screenMaxWidth,
  width: '100%',
  paddingTop: '18px',
  alignItems: 'center',
  display: 'flex',
  paddingLeft: '32px',
  lineHeight: 1.33,
  letterSpacing: 0.45,
});

const SelectedSpan = styled.span({
  fontWeight: theme.fontWeight.bold,
  lineHeight: 1.33,
});

const LinkText = styled.span({
  textDecoration: 'underline',
});

const BreadcrumbLink = styled(KexLink, {
  display: 'flex',
  opacity: 0.9,
  color: theme.breadText,
});

const Seperator = styled.span({
  margin: { x: '6px' },
  fontWeight: theme.fontWeight.normal,
  textDecoration: 'none',
});

export default Breadcrumb;
