import React from 'react';
import { styled, StyledProps } from '@glitz/react';

function ExclamationMarkIcon({ compose }: StyledProps) {
  return (
    <styled.Svg
      css={compose()}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
    >
      <path
        fill="#9A2626"
        fill-rule="nonzero"
        transform="translate(-781 -510) translate(765 172) translate(0 316) translate(16 22)"
        d="M8 0c4.418 0 8 3.582 8 8s-3.582 8-8 8-8-3.582-8-8 3.582-8 8-8zm0 1C4.134 1 1 4.134 1 8s3.134 7 7 7 7-3.134 7-7-3.134-7-7-7zm.5 10v2h-1v-2h1zm0-8v7h-1V3h1z"
      />
    </styled.Svg>
  );
}

export default styled(ExclamationMarkIcon);
