import React, { useState } from 'react';
import LoginPagePageModel from './Models/LoginPageModel.interface';
import useCurrentPage from '../Shared/Hooks/useCurrentPage';
import { styled, theme } from '../Theme';
import { media } from '@glitz/core';
import KexInput from '../Shared/Input/KexInput';
import { KexInputValidation } from '../Shared/Input/KexInputValidation';
import { SmallButton } from '../Shared/Button/Button';
import { ErrorIcon, LoadingCircle } from '../Shared/Icons';
import { StyleOrStyleArray } from '@glitz/type';
import { SignIn } from '../LoginPage/Account';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import UserCredentials from '../LoginPage/Models/UserCredentials.interface';
import Breadcrumb from '../Shared/Breadcrumb/Breadcrumb';
import { mediaQuery } from '../Theme/mediaQueries';
import SignInIcon from '../Shared/Icons/SignInIcon';
import CtaButton from '../Shared/CtaButton/CtaButton';
import KexLink from '../Shared/KexLink/KexLink';

function LoginPage() {
  const {
    loginText,
    forgotPasswordText,
    channelId,
    breadcrumb,
  } = useCurrentPage<LoginPagePageModel>();

  const [password, setPassword] = useState<string>('');
  const [username, setUsername] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState(false);
  const [validationMessage, setValidationMessage] = useState<string>('');

  const {
    translations: {
      'account/signIn': signInLabel,
      'account/username': usernameLabel,
      'account/password': passwordLabel,
      'account/forgotLogin': forgotLoginLabel,
      'customerInfo/customerContactPhone': customerContactPhone,
      'customerInfo/customerContactEmail': customerContactEmail,
    },
    languageRoute,
    requestToken,
    staticPages: { myPage },
  } = useAppSettingsData();

  const userCredentials: UserCredentials = {
    email: username,
    userName: username,
    password: password,
    language: languageRoute,
    newPassword: '',
  };
  const disabled = password.length === 0 || username.length === 0;

  const signInUser = () => {
    if (disabled) return;

    SignIn(
      userCredentials,
      requestToken,
      channelId,
      setError,
      setValidationMessage,
      setIsLoading,
      myPage
    );
  };

  const emailLink = 'mailto:' + customerContactEmail;

  return (
    <Main>
      <PageWrapper>
        {!!breadcrumb.length && <Breadcrumb breadcrumb={breadcrumb} />}
        <FormContainer>
          <FormTitleContainer>
            <StyledSignInIcon />
            <FromTitle>{signInLabel}</FromTitle>
          </FormTitleContainer>
          <FromDetailText>{loginText}</FromDetailText>
          {error && (
            <ValidationMessage>
              <ErrorIcon css={IconStyleError} />{' '}
              <ErrorMessage>{validationMessage}</ErrorMessage>
            </ValidationMessage>
          )}
          <KexInputValidation>
            <StyledKexInput
              title={usernameLabel}
              onChange={setUsername}
              onKeyDown={e => e.key === 'Enter' && signInUser()}
              isEmpty={!!!username.length}
            />
            <KexInput
              title={passwordLabel}
              type="password"
              onChange={setPassword}
              onKeyDown={e => e.key === 'Enter' && signInUser()}
              isEmpty={!!!password.length}
            />
          </KexInputValidation>
          <LoginButton disabled={disabled} onClick={signInUser}>
            {isLoading ? (
              <StyledLoadingCircle isLoading={isLoading} />
            ) : (
              <>{signInLabel}</>
            )}
          </LoginButton>
          <ForgottenLogIn>
            <ForgottenLonInText>{forgotLoginLabel}</ForgottenLonInText>
            <InformationStringLink>
              {customerContactPhone}
            </InformationStringLink>
            <InformationStringLink href={emailLink}>
              {customerContactEmail}
            </InformationStringLink>
          </ForgottenLogIn>
        </FormContainer>
      </PageWrapper>
    </Main>
  );
}

export default LoginPage;

const Main = styled.main({
  ...media(mediaQuery.mediaMaxSmall, { backgroundColor: theme.white }),
});

const PageWrapper = styled.div({
  padding: { x: '30px', top: '42px', bottom: '60px' },
  ...media(mediaQuery.mediaMinLarge, {
    padding: { x: 0, top: '54px', bottom: '80px' },
  }),
});

const StyledLoadingCircle = styled(LoadingCircle, {
  color: theme.white,
  height: theme.gamma,
  margin: { xy: 'auto' },
});

const IconStyleError: StyleOrStyleArray = {
  width: theme.gamma,
  height: theme.gamma,
  marginRight: theme.medium,
  marginTop: theme.small,
};

const FormTitleContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(3),
});

const StyledKexInput = styled(KexInput, { marginBottom: '25px' });

const StyledSignInIcon = styled(SignInIcon, {
  fill: theme.black,
  width: '24px',
  height: '24px',
  marginBottom: theme.spacing(1),
  ...media(mediaQuery.mediaMinLarge, {
    width: '30px',
    height: '30px',
  }),
});

const FormContainer = styled.div({
  font: { size: theme.psi, weight: theme.fontWeight.lighter },
  color: theme.black,
  lineHeight: theme.lineHeight.normal,
  letterSpacing: theme.letterSpacing.medium,
  ...media(theme.mediaQuery.mediaMinSmall, {
    borderRadius: '10px',
    backgroundColor: theme.white,
    padding: { x: '45px', y: '32px' },
    maxWidth: '554px',
    margin: { x: 'auto' },
  }),
  ...media(theme.mediaQuery.mediaMinLarge, {
    padding: { x: '90px', y: theme.massive },
  }),
});

const FromTitle = styled.h1({
  font: { size: '26px', weight: theme.fontWeight.bold },
  marginLeft: '10px',
  ...media(mediaQuery.mediaMinLarge, {
    fontSize: theme.great,
  }),
});

const FromDetailText = styled.p({
  font: { size: theme.gamma, weight: theme.fontWeight.normal },
  lineHeight: '25.6px',
  marginBottom: '38px',
});

const ValidationMessage = styled.div({
  font: { size: theme.gamma, weight: theme.fontWeight.normal },
  lineHeight: theme.lineHeight.relaxed,
  backgroundColor: theme.errorPrimary,
  color: theme.errorText,
  padding: { y: theme.huge, x: theme.huge },
  margin: { y: theme.huge },
  display: 'flex',
});

const ErrorMessage = styled.p({
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: '0%',
});

const LoginButton = styled(CtaButton, {
  fontSize: '15px',
  letterSpacing: theme.letterSpacing.medium,
  textTransform: 'uppercase',
  backgroundColor: theme.primaryBlue,
  color: theme.white,
  borderRadius: '100px',
  width: '100%',
  minHeight: theme.greater,
  margin: { y: '30px' },
});

const ForgottenLogIn = styled.div({
  display: 'flex',
  flexDirection: 'column',
});

const ForgottenLonInText = styled.p({
  fontSize: theme.gamma,
  color: theme.breadText,
});

const InformationStringLink = styled(KexLink, {
  color: theme.linkColor,
  cursor: 'pointer',
  textDecoration: 'underline',
  fontSize: theme.gamma,
  lineHeight: '1.4',
  marginTop: '8px',
});
