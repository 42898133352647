import React from 'react';
import { styled, StyledProps } from '@glitz/react';

function HeartIcon({ compose }: StyledProps) {
  return (
    <styled.Svg
      css={compose()}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12 10"
    >
      <path
        fill="#484848"
        d="M4.977.744l.144.135.879.878.879-.878.144-.135C8.2-.291 9.997-.246 11.12.879c1.125 1.124 1.17 2.92.135 4.098l-.135.144L6.345 9.86c-.193.184-.497.184-.69 0L.88 5.121C-.293 3.95-.293 2.051.879.88 2.003-.246 3.799-.291 4.977.744zm5.437.842C9.71.88 8.602.809 7.838 1.368l-.131.105-.121.113L6 3.17 4.437 1.608l-.124-.116-.13-.105c-.74-.544-1.763-.512-2.469.081l-.128.118c-.74.74-.78 1.915-.12 2.699l.117.126L6 8.792l4.392-4.355.113-.12c.692-.788.656-1.985-.09-2.731z"
      />
    </styled.Svg>
  );
}

export default styled(HeartIcon);
